body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
    box-sizing: border-box;
     height: 100%;
     
    */
  
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-font-smoothing: antialiased;   */
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
